import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private subject = new Subject<any>();
  public isLoggedIn: boolean = false;
  public isAdmin: boolean = false;
  currentUser:any;
  constructor(private router: Router) {
  }

  public isAuthenticated(): boolean {
   return localStorage.getItem("currentUser") != undefined;
  }

  public getCurrentUser():any{
    this.currentUser = localStorage.getItem("currentUser");
    return JSON.parse(this.currentUser);
  }
  
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.isLoggedIn = true;
      return true;
    }
    
    this.isLoggedIn = false;
    this.router.navigate(['authentication/login']);
    return false;
  }

  public logout(): void {

    localStorage.removeItem('currentUser');
    this.isLoggedIn = false;
    this.router.navigate(['/authentication/login']);
  }

}
